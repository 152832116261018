<template>
  <div class="columnd gap32 w100">
    <div class="btns rowd" :class="{ gap8: $route.name === 'Dashboard' }">
      <button
        @click="selectedTable = 'Active Orders'"
        :class="[
          { 'toggle-active': selectedTable === 'Active Orders' },
          {
            'b-llGrey c-darkBlue':
              $route.name === 'Dashboard' && selectedTable === 'Active Orders',
          },
        ]"
        class="toggle"
      >
        <span class="f16-700"> Active Orders </span>
        <transition name="borderrotate">
          <span
            v-if="selectedTable === 'Active Orders'"
            class="underline"
          ></span>
        </transition>
      </button>

      <button
        v-if="$route.name !== 'User'"
        @click="selectedTable = 'Active Posting'"
        :class="[
          { 'toggle-active': selectedTable === 'Active Posting' },
          {
            'b-llGrey c-darkBlue':
              $route.name === 'Dashboard' && selectedTable === 'Active Posting',
          },
        ]"
        class="toggle"
      >
        <span class="f16-700"> Active Posting </span>
        <transition name="borderrotate">
          <span
            v-if="selectedTable === 'Active Posting'"
            class="underline"
          ></span>
        </transition>
      </button>

      <button
        @click="selectedTable = 'Feedbacks'"
        :class="[
          { 'toggle-active': selectedTable === 'Feedbacks' },
          {
            'b-llGrey c-darkBlue':
              $route.name === 'Dashboard' && selectedTable === 'Feedbacks',
          },
        ]"
        class="toggle"
      >
        <span class="f16-700"> Feedbacks </span>
        <transition name="borderrotate">
          <span v-if="selectedTable === 'Feedbacks'" class="underline"></span>
        </transition>
      </button>
    </div>
    <ActiveOrders v-if="selectedTable === 'Active Orders'" />
    <ActivePosting v-if="selectedTable === 'Active Posting'" />
    <FeedBacks v-if="selectedTable === 'Feedbacks'" />
  </div>
</template>

<script>
import ActiveOrders from "../Tables/ActiveOrders.vue";
import ActivePosting from "../Tables/ActivePosting.vue";
import FeedBacks from "../Tables/FeedBacks.vue";
export default {
  name: "ProfileTable",
  components: {
    ActiveOrders,
    ActivePosting,
    FeedBacks,
  },
  data() {
    return {
      selectedTable: "Active Orders",
    };
  },
};
</script>

<style lang="scss" scoped>
.toggle-active {
  background: var(--white);
  color: var(--darkBlue);
}
</style>
