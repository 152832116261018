<template>
  <div class="mini-container rowd gap16 space-between">
    <img class="br8" src="../../../assets/test/fakeprofile.svg" alt="" />
    <div class="user-info columnd gap12">
      <h3 class="f22-700 c-black text-start">Rayan Gaslin</h3>
      <div class="isonline">
        <span class="circle on"></span>
        <span class="f14-700 c-black"> online </span>
      </div>
      <span class="f14-400 c-darkGrey text-start">
        Our company can use a large number of gift cards, debit cards, credit
        cards, game cards every day. Looking for the world's largest partner!
        Good faith long-term cooperation!!</span
      >
      <div class="rowd gap8">
        <div class="item-container rowd align-center">
          <span class="f12-400 c-darkGrey"> Country:</span>
          <span class="f14-700 c-darkBlue">USA</span>
        </div>
        <div class="item-container rowd align-center">
          <span class="f12-400 c-darkGrey"> City:</span>
          <span class="f14-700 c-darkBlue">NY</span>
        </div>
      </div>
    </div>
    <div style="min-width: 180px" class="columnd space-between gap12">
      <div v-if="$route.name === 'User'" class="rowd space-between gap8 wrap">
        <div class="social-container">
          <inline-svg
            class="fill-darkBlue"
            :src="require('../../../assets/Icons/facebook.svg')"
          />
        </div>
        <div class="social-container">
          <inline-svg
            class="fill-darkBlue"
            :src="require('../../../assets/Icons/twitter.svg')"
          />
        </div>
        <div class="social-container">
          <inline-svg
            class="fill-darkBlue"
            :src="require('../../../assets/Icons/email.svg')"
          />
        </div>
      </div>
      <button
        @click.prevent="$router.push({ name: 'Validation' })"
        v-if="$route.name === 'Profile'"
        class="secondary-btn w100 rowd space-between align-center f12-700"
      >
        Edit Profile
        <inline-svg :src="require('../../../assets/Icons/edit.svg')" />
      </button>
      <div class="feedbacks columnd">
        <div class="rowd space-between">
          <inline-svg
            class="fill-green"
            :src="require('../../../assets/Icons/bilakh.svg')"
          />
          <span class="f16-700 c-green">125</span>
        </div>
        <div class="rowd space-between">
          <inline-svg
            class="fill-red rotate-up"
            :src="require('../../../assets/Icons/bilakh.svg')"
          />
          <span class="f16-700 c-red">43</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalInfo",
};
</script>

<style lang="scss" scoped>
.social-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: var(--llGrey);
  padding: 12px;
  cursor: pointer;
  &:hover {
    svg {
      fill: var(--darkCryan);
    }
  }
}
.feedbacks {
  border-radius: 8px;
  background-color: var(--llGrey);
  padding: 16px;
  gap: 24px;
  justify-content: space-between;
}
.user-info {
  max-width: 70%;
}
</style>
