<template>
  <div class="table-container columnd gap24">
    <div class="btns rowd">
      <button
        @click="buy = true"
        :class="{ 'toggle-active': buy }"
        class="toggle"
      >
        <span class="f16-700"> From Crypto Buyers </span>
        <transition name="borderrotate">
          <span v-if="buy" class="underline"></span>
        </transition>
      </button>

      <button
        @click="buy = false"
        :class="{ 'toggle-active': !buy }"
        class="toggle"
      >
        <span class="f16-700"> From Crypto Sellers </span>
        <transition name="borderrotate">
          <span v-if="!buy" class="underline"></span>
        </transition>
      </button>
    </div>
    <div class="table columnd gap12">
      <div
        :class="{ 'dash-header': $route.name === 'Dashboard' }"
        class="header rowd w100 space-between"
      >
        <span style="flex: 2" class="justify-start">Users</span>
        <span style="flex: 1.5">{{ buy ? "Pay via" : "Get Paied via" }}</span>
        <span style="flex: 1.5" class="justify-end">Rate Per BTC</span>
      </div>
      <div
        v-for="i in 10"
        :key="i"
        class="body columnd w100 space-between gap8"
      >
        <div class="w100 rowd">
          <div class="rowd gap4 align-center justify-start" style="flex: 2">
            <img
              class="br8"
              src="../../../assets/test/fakeprofile.svg"
              alt=""
            />
            <div class="columnd gap8">
              <h4 class="f14-700 c-darkBlue">Ryan Gasling</h4>
              <span class="f14-400 c-medGrey">June12,2022</span>
            </div>
          </div>
          <div class="columnd gap8" style="flex: 1.5">
            <span class="f14-400 c-darkGrey justify-center">Pay Pal</span>
            <span class="f14-400 c-medGrey justify-center">USD</span>
          </div>
          <span
            class="f12-400 c-green justify-end gap4 align-center"
            style="flex: 1.5"
          >
            <inline-svg
              class="fill-green"
              :src="require('../../../assets/Icons/bilakh.svg')"
            />
            Positive
          </span>
        </div>
        <span class="f14-400 c-medGrey w100 justify-start">
          Really nice and kind seller. He‘s fast asf
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Feedsback",
  data() {
    return {
      buy: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.toggle-active {
  background: unset !important;
}
</style>
