<template>
  <div class="table-container columnd gap24 w100">
    <div class="btns rowd">
      <button
        @click="buy = true"
        :class="{ 'toggle-active': buy }"
        class="toggle"
      >
        <span class="f16-700"> Buy </span>
        <transition name="borderrotate">
          <span v-if="buy" class="underline"></span>
        </transition>
      </button>

      <button
        @click="buy = false"
        :class="{ 'toggle-active': !buy }"
        class="toggle"
      >
        <span class="f16-700"> Sell </span>
        <transition name="borderrotate">
          <span v-if="!buy" class="underline"></span>
        </transition>
      </button>
    </div>
    <div class="table columnd gap12">
      <div
        :class="{ 'dash-header': $route.name === 'Dashboard' }"
        style="z-index: 5"
        class="header rowd w100 space-between b-llGrey"
      >
        <span class="remove" style="flex: 1; text-align: start">Id</span>
        <span style="flex: 2">Posting Time</span>
        <span style="flex: 1.5">{{ buy ? "Pay via" : "Get Paied via" }}</span>
        <span style="flex: 2">Limit</span>
        <span style="flex: 2">Amount</span>
        <span style="flex: 1.5">Total</span>
        <span style="flex: 1.5"></span>
      </div>
      <div
        v-for="i in 4"
        :key="i"
        @click="openInfos(i)"
        :class="{ droped: dropinfos === i }"
        class="body columnd w100 pointer align-center gap12"
      >
        <div
          class="rowd w100 space-between pointer stick-top"
          :class="{ 'b-lightGrey': dropinfos === i }"
        >
          <span
            class="remove"
            style="flex: 1; text-align: start; justify-content: start"
          >
            7721
          </span>
          <span
            class="f14-400 remove c-medGrey"
            style="flex: 2; text-align: center; justify-content: center"
          >
            3June,22:45
          </span>
          <span class="f14-400 c-medGrey" style="flex: 1.5">Pay Pal</span>
          <span class="f14-400 c-medGrey" style="flex: 2"
            >2,000 - 15,000 USD
          </span>
          <span class="f14-400 c-medGrey" style="flex: 2">0.001 ETH</span>
          <span class="f16-700 c-darkGrey" style="flex: 1.5">1.3 USD</span>
          <span class="f12-400 c-red" style="flex: 1.5">
            <inline-svg
              class="fill-red"
              :src="require('../../../assets/Icons/close.svg')"
            />
            Cancel
          </span>
        </div>
        <transition name="heightfomup">
          <div v-if="dropinfos === i" class="more-conatiner w100 columnd gap8">
            <div class="tags rowd justify-start w100">
              <span class="f12-400 c-ddGrey">Bank Transfer</span>
              <span class="f12-400 c-ddGrey">PayPal</span>
              <span class="f12-400 c-ddGrey">Skrill</span>
            </div>
            <div class="underline2 stick-top"></div>
            <PostCustumers :buy="buy" />
          </div>
        </transition>
        <inline-svg
          :class="dropinfos === i ? 'arow-open' : 'arow'"
          class="arow medgrey-svg"
          :src="require('../../../assets/Icons/arrow.svg')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PostCustumers from "./PostCustumers.vue";
export default {
  components: { PostCustumers },
  name: "ActiveOrders",
  data() {
    return {
      buy: true,
      dropinfos: null,
    };
  },
  methods: {
    openInfos(id) {
      if (this.dropinfos === id) {
        this.dropinfos = null;
      } else {
        this.dropinfos = id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.more-conatiner {
  transform-origin: top;
}
.droped {
  background: var(--lightGrey);
  border-radius: 8px;
}
.underline2 {
  width: 100%;
  height: 1px;
  background: var(--white);
  border-radius: 24px;
}
.tags {
  position: sticky;
  top: 30px;
  background: var(--lightGrey);
  z-index: 2;
  span {
    background: white;
  }
}
.arow {
  transform: translateX(-20px);
}
.arow-open {
  transform: translateX(-20px) rotate(180deg);
}
.toggle-active {
  background: unset !important;
}
</style>
